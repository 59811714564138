import { EnvironmentUtils, Environment } from '~anyx/shared/utils';

import {
  ANYCHAT_PROD_APP_DOMAIN,
  ANYCHAT_STG_APP_DOMAIN,
  ANYLOGI_PROD_APP_DOMAIN,
  ANYLOGI_STG_APP_DOMAIN,
  ANYX_PROD_APP_DOMAIN,
  ANYX_STG_APP_DOMAIN,
} from '../constants';
import { DomainProduct } from '../types';

export const getCoreAppDomain = (product: DomainProduct) => {
  const environment = EnvironmentUtils.getCurrentEnvironment();

  if (environment === Environment.PRODUCTION) {
    if (product === DomainProduct.ANYCHAT) {
      return ANYCHAT_PROD_APP_DOMAIN;
    }

    if (product === DomainProduct.ANYLOGI) {
      return ANYLOGI_PROD_APP_DOMAIN;
    }

    return ANYX_PROD_APP_DOMAIN;
  }

  if (product === DomainProduct.ANYCHAT) {
    return ANYCHAT_STG_APP_DOMAIN;
  }

  if (product === DomainProduct.ANYLOGI) {
    return ANYLOGI_STG_APP_DOMAIN;
  }

  return ANYX_STG_APP_DOMAIN;
};
