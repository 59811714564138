import { useMemo } from 'react';

import { DomainProduct } from '../types';
import { getCoreAppDomain } from '../utils';

export const useCoreAppDomain = ({ product }: { product: DomainProduct }) => {
  const coreAppDomain = useMemo(() => {
    return getCoreAppDomain(product);
  }, [product]);

  return { coreAppDomain };
};
