import { FieldPolicy, Reference } from '@apollo/client';

import { ApolloClientHelpers, Chats } from '~anyx/shared/graphql';

const chatsQueryPolicy: FieldPolicy<Omit<Chats, 'chats'> & { chats: Reference[] }> = {
  keyArgs: ['input', ['storeId', 'keyword', 'chatTypeFilter', 'messagingChannel', 'tagName']],
  merge(existing, incoming) {
    const existingChats = existing?.chats || [];
    const incomingChats = incoming?.chats || [];

    const incomingChatId: Record<string, number> = incomingChats.reduce((prev, curr, index) => {
      return {
        ...prev,
        [curr.__ref]: index,
      };
    }, {});

    const filteredExisting = existingChats.filter(
      (chat) => incomingChatId[chat.__ref] === undefined
    );

    return {
      ...incoming,
      hasNext: existing?.hasNext === false ? false : incoming.hasNext,
      chats: [...incomingChats, ...filteredExisting],
    };
  },
};

const chatQueryPolicy: FieldPolicy = {
  keyArgs: ['input', ['chatId']],
  read(_, { args, toReference }) {
    return toReference({
      __typename: 'Chat',
      id: args?.['input']?.['chatId'],
    });
  },
};

const lineChatEventsQueryPolicy: FieldPolicy = {
  keyArgs: ['input', ['chatId', 'keyword']],
};

const instagramChatEventsQueryPolicy: FieldPolicy = {
  keyArgs: ['input', ['chatId', 'keyword']],
};

const messengerChatEventsQueryPolicy: FieldPolicy = {
  keyArgs: ['input', ['chatId', 'keyword']],
};

const whatsappChatEventsQueryPolicy: FieldPolicy = {
  keyArgs: ['input', ['chatId', 'keyword']],
};

const lazadaChatEventsQueryPolicy: FieldPolicy = {
  keyArgs: ['input', ['chatId', 'keyword']],
};

const ShopeeChatEventsV2QueryPolicy: FieldPolicy = {
  keyArgs: ['input', ['chatId', 'keyword']],
};

const chatCustomerDetailQueryPolicy: FieldPolicy = {
  keyArgs: ['input', ['chatId']],
};

const customerActivitiesQueryPolicyV2: FieldPolicy = {
  keyArgs: ['input', ['customerId']],
  // combine incoming records, but maintain the total count from the initial fetch
  merge(existing = { records: [] }, incoming = { records: [] }, { args }) {
    if (args?.['input']?.['olderThan']) {
      return {
        ...incoming,
        total: existing?.total || incoming?.total,
        records: [...existing.records, ...incoming.records],
      };
    }

    if (args?.['input']?.['newerThan']) {
      return {
        ...incoming,
        total: existing?.total || incoming?.total,
        records: [incoming.records, ...existing.records],
      };
    }

    return {
      ...incoming,
    };
  },
};

const customerOrdersQueryPolicy: FieldPolicy = {
  keyArgs: ['input', ['customerId']],
};

const customerProfileQueryPolicy: FieldPolicy = {
  keyArgs: ['input', ['customerId']],
};

const anychatStoreQueryPolicy: FieldPolicy = {
  keyArgs: ['input', ['id']],
  merge: true,
};

export const ChatFunctionApolloQueryPolicies: ApolloClientHelpers.QueryFieldPolicy = {
  Chats: chatsQueryPolicy,
  Chat: chatQueryPolicy,
  LineChatEvents: lineChatEventsQueryPolicy,
  InstagramChatEvents: instagramChatEventsQueryPolicy,
  MessengerChatEvents: messengerChatEventsQueryPolicy,
  WhatsAppChatEvents: whatsappChatEventsQueryPolicy,
  LazadaChatEvents: lazadaChatEventsQueryPolicy,
  ShopeeChatEventsV2: ShopeeChatEventsV2QueryPolicy,
  ChatCustomerDetail: chatCustomerDetailQueryPolicy,
  CustomerActivitiesV2: customerActivitiesQueryPolicyV2,
  CustomerOrders: customerOrdersQueryPolicy,
  CustomerProfile: customerProfileQueryPolicy,
  AnyChatStore: anychatStoreQueryPolicy,
};
