import { FieldPolicy, Reference } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { t } from 'i18next';

import {
  ApolloClientHelpers,
  MessageState,
  WhatsAppMessageDeliveryStatus,
} from '~anyx/shared/graphql';
import { LanguageUtils } from '~anyx/shared/utils';

enum AuthorId {
  ANONYMOUS_USER = 'Anonymous User',
  REPLIED_FROM_INSTAGRAM = 'Replied From Instagram',
  REPLIED_FROM_MESSENGER = 'Replied From Messenger',
  REPLIED_FROM_WHATSAPP = 'Replied From WhatsApp',
  RELIED_FROM_LAZADA = 'Replied From Lazada',
  REPLIED_FROM_SHOPEE = 'Replied From Shopee',
}

const messageStateFieldPolicy: FieldPolicy = {
  read(messageState = MessageState.SENT) {
    return messageState;
  },
};

const chatEventsFieldPolicy = (
  getMessageState: (message: Reference, readField: ReadFieldFunction) => MessageState | undefined
): FieldPolicy<Reference[]> => ({
  merge(existing = [], incoming = [], { variables, readField, mergeObjects }) {
    const merged: Reference[] = [];

    const messageIdToIndex: Record<string, number> = Object.create(null);

    existing.forEach((message) => {
      const id = readField<string>('id', message);
      const messageState = getMessageState(message, readField);

      if (id) {
        if (
          !Object.prototype.hasOwnProperty.call(messageIdToIndex, id) &&
          messageState !== MessageState.WAIT_FOR_DISCARD
        ) {
          const count = merged.push(message);
          messageIdToIndex[id] = count - 1;
        }
      }
    });

    const olderMessages: Reference[] = [];

    incoming.forEach((message) => {
      const id = readField<string>('id', message);

      if (id) {
        const index = messageIdToIndex[id];
        if (typeof index === 'number') {
          merged[index] = mergeObjects(merged[index] as Reference, message);
        } else {
          if (variables?.['olderThan'] || variables?.['before'] || variables?.['olderCursor']) {
            olderMessages.push(message);
          } else {
            merged.push(message);
          }
        }
      }
    });

    return [...olderMessages, ...merged];
  },
});

const olderCursorFieldPolicy: FieldPolicy = {
  merge(existing, incoming, { variables }) {
    const queryOlderCursor = variables?.['olderCursor'];

    if (queryOlderCursor === undefined && existing !== undefined) {
      return existing;
    }

    return incoming;
  },
};

const newerCursorFieldPolicy: FieldPolicy = {
  merge(existing, incoming, { variables }) {
    const queryOlderCursor = variables?.['olderCursor'];

    if (queryOlderCursor !== undefined) {
      return existing;
    }
    return incoming;
  },
};

export const ChatFunctionApolloFieldPolicies: ApolloClientHelpers.StrictTypedTypePolicies = {
  LineBotTextMessageEvent: {
    fields: {
      messageState: messageStateFieldPolicy,
    },
  },
  InstagramBotMessage: {
    fields: {
      messageState: messageStateFieldPolicy,
    },
  },
  MessengerBotMessage: {
    fields: {
      messageState: messageStateFieldPolicy,
    },
  },
  WhatsAppStoreCSMessage: {
    fields: {
      messageState: messageStateFieldPolicy,
    },
  },
  LazadaStoreCSMessage: {
    fields: {
      messageState: messageStateFieldPolicy,
    },
  },
  ShopeeStoreCSMessage: {
    fields: {
      messageState: messageStateFieldPolicy,
    },
  },
  LineChatEvents: {
    keyFields: ['chatId'],
    merge: true,
    fields: {
      chatEvents: chatEventsFieldPolicy((message, readField) =>
        readField('messageState', readField('message', message))
      ),
    },
  },
  InstagramChatEvents: {
    keyFields: ['chatId'],
    merge: true,
    fields: {
      chatEvents: chatEventsFieldPolicy((message, readField) =>
        readField('messageState', readField('message', message))
      ),
      olderCursor: olderCursorFieldPolicy,
      newerCursor: newerCursorFieldPolicy,
    },
  },
  MessengerChatEvents: {
    keyFields: ['chatId'],
    merge: true,
    fields: {
      chatEvents: chatEventsFieldPolicy((message, readField) =>
        readField('messageState', readField('message', message))
      ),
      olderCursor: olderCursorFieldPolicy,
      newerCursor: newerCursorFieldPolicy,
    },
  },
  WhatsAppChatEvents: {
    keyFields: ['chatId'],
    merge: true,
    fields: {
      chatEvents: {
        ...chatEventsFieldPolicy((message, readField) =>
          readField('messageState', readField('message', message))
        ),

        read(chatEvents: Reference[], { readField, mergeObjects }) {
          if (!chatEvents) return [];
          chatEvents.forEach((event) => {
            const rawMessage: Reference | undefined = readField('message', event);

            if (rawMessage) {
              const chatEventRef = readField('__ref', event);
              const rawMessageRef = readField('__ref', rawMessage);
              const status = readField('status', event);
              const currentState = readField('messageState', rawMessage);

              const messageWithState = mergeObjects(rawMessage, {
                __ref: rawMessageRef,
                messageState:
                  status === WhatsAppMessageDeliveryStatus.FAILED
                    ? MessageState.FAIL
                    : currentState,
              } as Reference);

              mergeObjects(event, {
                __ref: chatEventRef,
                message: messageWithState,
              } as Reference);
            }
          });

          return [...chatEvents];
        },
      },
      olderCursor: olderCursorFieldPolicy,
      newerCursor: {
        // In order to obtain the sent failed message event if exists, use the same cursor from the beginning of the connection
        merge(existing, incoming) {
          if (existing !== undefined) {
            return existing;
          }

          return incoming;
        },
      },
    },
  },
  LazadaChatEvents: {
    keyFields: ['chatId'],
    merge: true,
    fields: {
      chatEvents: chatEventsFieldPolicy((message, readField) =>
        readField('messageState', readField('message', message))
      ),
      olderCursor: olderCursorFieldPolicy,
      newerCursor: newerCursorFieldPolicy,
    },
  },
  ShopeeChatEvents: {
    keyFields: ['chatId'],
    merge: true,
    fields: {
      chatEvents: chatEventsFieldPolicy((message, readField) =>
        readField('messageState', readField('message', message))
      ),
      olderCursor: olderCursorFieldPolicy,
      newerCursor: newerCursorFieldPolicy,
    },
  },
  ChatCustomerDetail: {
    keyFields: ['chatId'],
    merge: true,
  },
  LineBroadcastMessage: {
    keyFields: ['id'],
    fields: {
      statistics: {
        merge: true,
      },
    },
  },
  LineRichMenuTemplate: {
    keyFields: ['id', 'actions'],
  },
  BasicUser: {
    keyFields: ['id'],
    merge(existing, incoming, options) {
      const firstName = options.readField<string | undefined>('firstName', incoming);
      const lastName = options.readField<string | undefined>('lastName', incoming);
      const authorId = options.readField<string | undefined>('id', incoming);

      let computedName = '';

      const originalMerge = options.mergeObjects(existing, incoming);

      switch (authorId) {
        case AuthorId.ANONYMOUS_USER:
          computedName = t('anychat.chat.chatBubbles.authors.anonymous', { ns: 'anychat' });
          break;
        case AuthorId.REPLIED_FROM_INSTAGRAM:
          computedName = t('anychat.chat.chatBubbles.authors.instagram', { ns: 'anychat' });
          break;
        case AuthorId.REPLIED_FROM_MESSENGER:
          computedName = t('anychat.chat.chatBubbles.authors.messenger', { ns: 'anychat' });
          break;
        case AuthorId.REPLIED_FROM_WHATSAPP:
          computedName = t('anychat.chat.chatBubbles.authors.whatsapp', { ns: 'anychat' });
          break;
        case AuthorId.RELIED_FROM_LAZADA:
          computedName = t('anychat.chat.chatBubbles.authors.lazada', { ns: 'anychat' });
          break;
        case AuthorId.REPLIED_FROM_SHOPEE:
          computedName = t('anychat.chat.chatBubbles.authors.shopee', { ns: 'anychat' });
          break;
        default:
          computedName = LanguageUtils.localizeName(firstName, lastName);
          break;
      }

      if (computedName) {
        return options.mergeObjects(originalMerge, {
          name: computedName,
        });
      }

      return originalMerge;
    },
  },
};
