import { DomainProduct } from '../types';

export const getDomainProduct = () => {
  if (window.location.host.includes('anylogi.com')) {
    return DomainProduct.ANYLOGI;
  }
  if (window.location.host.includes('anychat.tech')) {
    return DomainProduct.ANYCHAT;
  }
  return DomainProduct.ANYX;
};
