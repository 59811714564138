import { DomainProduct } from '../types';

export const getAppNameByDomainProduct = (product: DomainProduct) => {
  switch (product) {
    case DomainProduct.ANYLOGI:
      return 'AnyLogi';
    case DomainProduct.ANYCHAT:
      return 'AnyChat';
    default:
      return 'AnyX';
  }
};
