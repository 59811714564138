import { DomainProduct } from '~anyx/shared/domain-product';

import { Theme } from '../themes';

export class ThemeUtils {
  static getProductTheme = (product: DomainProduct) => {
    switch (product) {
      case DomainProduct.ANYLOGI:
        return Theme.ANYLOGI;
      case DomainProduct.ANYCHAT:
        return Theme.ANYCHAT;
      default:
        return Theme.ANYX;
    }
  };
}
