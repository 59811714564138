export class StringUtils {
  static capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  static isNotEmpty = (value: unknown) => {
    return typeof value === 'string' && value.trim().length > 0;
  };

  static toPascalCase = (s: string) => {
    return s
      .split(/[\s-_]+/)
      .map((chr) => chr.charAt(0).toUpperCase() + chr.toLowerCase().slice(1))
      .join('');
  };
}
