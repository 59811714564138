import { Navigate } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { FALLBACK_ROUTE } from '~anyx/common/routing';
import { AccountFunctionRoutes } from '~anyx/function/account';
import { LogisticFunctionRoutes } from '~anyx/function/logistic';
import { MarketplaceFunctionRoutes } from '~anyx/function/marketplace';
import { PolicyModuleRoutes } from '~anyx/function/policy';
import { ReportFunctionRoutes } from '~anyx/function/report';
import { ShippingFunctionRoutes } from '~anyx/function/shipping';
import { WorkflowFunctionRoutes } from '~anyx/function/workflow';
import { ChatFunctionRoutes } from '~anyx/function-chat';

import { sentryCreateBrowserRouter } from '../monitoring';

import { App } from './app';
import { ErrorModuleRoutes } from './modules/errors';
import { ErrorNotFoundPage } from './modules/errors/pages';
import { HomeModuleRoutes } from './modules/home';
import { IndexPage } from './modules/index';
import { OnboardingModuleRoutes } from './modules/onboarding';

export const getRouter = () => {
  return sentryCreateBrowserRouter([
    {
      path: ':workspaceMasterStoreDataId?/',
      element: <App />,
      errorElement: <ErrorNotFoundPage />,
      children: [
        {
          index: true,
          element: (
            <Auth0Guard>
              <IndexPage />
            </Auth0Guard>
          ),
        },
        ...AccountFunctionRoutes,
        ...ChatFunctionRoutes,
        ...HomeModuleRoutes,
        ...LogisticFunctionRoutes,
        ...MarketplaceFunctionRoutes,
        ...OnboardingModuleRoutes,
        ...ReportFunctionRoutes,
        ...ShippingFunctionRoutes,
        ...WorkflowFunctionRoutes,
        ...PolicyModuleRoutes,
        ...ErrorModuleRoutes,
        {
          path: '*',
          element: <Navigate to={FALLBACK_ROUTE} replace={true} />,
        },
      ],
    },
  ]);
};
