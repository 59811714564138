import { initReactI18next } from 'react-i18next';

import i18n, { InitOptions } from 'i18next';
import ICU from 'i18next-icu';
import resourcesToBackend from 'i18next-resources-to-backend';

export const initI18n = (options: InitOptions) => {
  return i18n
    .use(ICU)
    .use(initReactI18next)
    .use(
      resourcesToBackend(
        (language: string, namespace: string) => import(`./locales/${language}/${namespace}.json`)
      )
    )
    .init(options);
};
